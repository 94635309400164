import image1 from "../assets/7.jpg";
import image2 from "../assets/9.jpg";
import image3 from "../assets/1.jpg";
import image4 from "../assets/11.jpg";
export const testimonialsData = [
  {
    image: image1,
    review:
      "FitClub is a welcoming space for all, where both men and women find strength, camaraderie, and empowerment. Our top-notch facilities, personalized coaching, and supportive community cater to diverse fitness goals and journeys. Join us and unlock your full potential at FitClub.",
    name: 'ROHIT SINGH',
    status : 'OWNER'
  },
  {
    image: image2,
    review: 'Training with Coach at Fit Club has been transformative. Their expertise and support have propelled me towards my goals like never before ',
    name: 'BHOMIK KANDPAL',
    status: 'COACH'
  },
  {
    image : image3,
    review:' Fit Club is simply fantastic! The staff is supportive, the equipment is top-notch, and the atmosphere is always positive. Highly recommend!',
    name: 'NARENDRA SINGH BISHT',
    status: "CUSTOMER"
  },
  {
    image : image4,
    review:'Fitclub is an exceptional fitness destination for women. With a welcoming atmosphere, top-notch facilities, and empowering amenities, its the perfect place to achieve your fitness goals and feel confident every step of the way.',
    name: 'SUNAINA BISHT',
    status: "CUSTOMER"
  }
];
